export default `
### Q：强化状态

A：一部分的技能/必杀技有强化状态。

※对'强化干扰'状态的角色生效的情况下会变成"强化失败"，不给予效果。

-   **关于效果的重复**


-   状态上升

    可以同时给予效果。

    ※效果和效果回合是分别计算的。

-   特殊状态

    可以同时给予效果。

    ※效果回合也不改变。

-   状态上升

作用于以下状态，各个数值会发生变化。

-   物理攻击力增加

-   异能攻击力增加

-   物理防御力增加

-   异能防御力增加

-   ＳＰ获得量增加

-   物理伤害降低

-   异能伤害降低

-   红属性伤害降低

-   蓝属性伤害降低

-   绿属性伤害降低

-   黄属性伤害降低

-   紫属性伤害降低

-   暴击发生率增加

-   暴击回避率增加

-   暴击威力增加


-   **特殊状态**

    会发生特殊的效果


-   护盾

    无效攻击造成的伤害

    ※异常状态造成的伤害不能无效。

    ※对于减少HP的效果不会发动。

    ※来自于附加了'贯穿'的对象的攻击无法无效。

    ※被附加'制御不能'时，无法无效化伤害。

-   矢量操作

    受到所属侧包含'科学侧'的攻击的伤害的情况下，将伤害无效化再进行反击。

    ※异常状态造成的伤害不能无效。

    ※对于减少HP的效果不会发动。

    ※受到'矢量操作'与'强制咏唱待机'的伤害效果不发动。

    ※'矢量操作'与'强制咏唱待机'同时满足发动条件时，'矢量操作'优先发动。

    ※来自于附加了'贯穿'的对象的攻击无法无效。

    ※被附加'制御不能'时，无法无效化伤害。

-   强制咏唱待机

    受到所属侧包含'魔法侧'的攻击的伤害的情况下，将伤害无效化再进行反击。

    ※异常状态造成的伤害不能无效。

    ※对于减少HP的效果不会发动。

    ※受到'矢量操作'与'强制咏唱待机'的伤害效果不发动。

    ※'矢量操作'与'强制咏唱待机'同时满足发动条件时，'矢量操作'优先发动。

    ※来自于附加了'贯穿'的对象的攻击无法无效。

    ※被附加'制御不能'时，无法无效化伤害。

-   天罚术式

    无效化攻击伤害，给予那个敌人气绝。

    ※异常状态造成的伤害不能无效。

    ※对于减少HP的效果不会发动。

    ※受到'矢量操作'与'强制咏唱待机'的伤害效果不发动。

    ※来自于附加了'贯穿'的对象的攻击无法无效。

    ※被附加'制御不能'时，无法无效化伤害。

-   外部供奉

    无效化攻击伤害，给予自身物攻增加和暴击发生率增加。

    ※异常状态造成的伤害不能无效。

    ※对于减少HP的效果不会发动。

    ※受到'矢量操作'与'强制咏唱待机'的伤害效果不发动。

    ※来自于附加了'贯穿'的对象的攻击无法无效。

    ※被附加'制御不能'时，无法无效化伤害。

-   集中状态

    对目标集中状态的角色以外的角色，攻击方向影响的效果无效化。

    另外，目标集中状态的角色不能连携。

    ※除了攻击方向以外会受到指定范围的效果的影响。（全体攻击等）

-   攻击方向+1，+2

    详情请去观看Q：关于强化状态和弱体化状态的攻击方向的项目。

-   攻击方向变化

    攻击方向随机变为1个方向。

-   暴击必中

    给予伤害时，一定会造成暴击。

-   感电耐性

    防止异常状态'感电'。

-   必杀技封印耐性

    防止异常状态'必杀技封印'。

-   辅助封印耐性

    防止异常状态'辅助封印'。

-   贯穿

    攻击时，进行无视对象特殊状态的攻击。

    ※因为附加了'贯穿'的攻击无视'无效伤害的效果'，所以不会消耗'无效伤害的效果'的次数。

    ※因为'不屈'不是'无效伤害的效果'，所以无法无视。

-   弱体化状态耐性

    防止异常状态'弱体化状态'。

-   强化解除耐性

    防止异常状态'强化解除'。

-   不屈

    受到伤害HP变成 0后能够继续战斗，并恢复自身的HP。

    HP的恢复量受'灵巧'的状态影响。

    ※当在受到异常状态'HP恢复妨碍'状态时HP变为0的时候，由于'不屈'的HP恢复不能发动，所以会变为不能战斗。
`