export default `
### Q：技能/必杀技效果

A：技能/必杀技有着不同的效果

-   **技能/必杀技效果的种类**

-   攻击。

    效果是进行攻击造成伤害。

-   HP恢复

    效果是恢复HP。

    HP的恢复量受'灵巧'的状态影响。

-   HP减少

    效果是减少HP。

-   附加强化状态

    效果是附加有利的状态变化的强化状态。

-   附加弱体化状态

    效果是附加不利的状态变化的弱体化状态。

-   强化状态解除

    效果是解除强化状态。

-   弱体化状态解除

    效果是解除弱体化状态。

-   SP变化

    效果是改变SP值

    ※对没有SP值的对象不起作用。

-   行动条变化

    效果是改变行动条值

    ※对没有行动条的对象不起作用。

-   冷却速度变化

    效果是改变冷却速度

    ※对没有冷却速度的对象不起作用。

-   **拥有特别效果的攻击**

-   自身HP值越低伤害越高

    使用效果的战斗角色现在剩下的HP的比例越少伤害就越高。

    ※根据最大HP和当前HP的比例效果会发生变动。

-   对方HP值越高伤害越高

    效果对象的战斗角色现在剩下的HP的比例越高伤害就越高。

    ※根据最大HP和当前HP的比例效果会发生变动。

-   无视使伤害无效的特殊效果的攻击

    攻击的时候，进行无视对方的'使伤害无效'特殊状态的攻击。

    ※与附加了特殊状态'贯穿'的攻击同效果。

-   当自己的HP值最大时，威力会上升

    使用效果的战斗角色现在HP最大时伤害提高。

-   根据自身被赋予的强化状态的数量威力上升的攻击

    使用效果的战斗角色的强化状态（状态上升、特殊状态）的数量越多伤害就会上升。

    ※最多计数到10

-   将自身物理防御力作为物理攻击力来使用的攻击

    攻击时，进行将物理攻击力替换为物理防御力的状态来计算伤害的物理攻击。

    ※物攻上升、物攻下降的状态上升/状态下降效果不会影响这个攻击所带来的伤害。

    ※物防上升、物防下降的状态上升/状态下降效果会影响这个攻击给予的伤害。

    ※潜能的物理攻击力上升效果不会影响这个攻击带来的伤害。

    ※潜能的物理防御力上升效果会影响这个攻击带来的伤害。

    ※潜能的向各方向（前方向、左方向、右方向）的攻击力上升效果会影响这个攻击所带来的伤害。

    ※潜能对各势力（科学侧、魔术侧）的威力上升效果会影响这个攻击所带来的伤害。

    ※潜能对每个属性（红、蓝、绿、黄、紫）物理攻击力上升的效果不会影响这个攻击带来的伤害。

    ※潜能对每个属性（红、蓝、绿、黄、紫）物理防御力上升的效果不会影响这个攻击带来的伤害。

-   **强化状态解除的种类**

-   解除强化状态

    解除强化状态（状态上升、特殊状态）。

-   解除状态上升状态

    解除强化状态中的状态上升状态。

-   解除特殊状态

    解除强化状态中的特殊状态

-   解除攻击力增加状态

    解除状态上升状态中的物理攻击力上升状态和异能攻击力上升状态。

-   解除防御力增加状态

    解除状态上升状态中的物理防御力上升状态和异能防御力上升状态。

-   **弱体化状态解除的种类**

-   解除弱体化状态

    解除弱体化状态（状态下降、异常状态）。

-   解除状态下降状态

    解除弱体化状态中的状态下降状态。

-   解除异常状态

    解除弱体化状态中的异常状态

-   解除攻击力下降状态

    解除状态下降状态中的物理攻击力下降状态和异能攻击力下降状态。

-   解除防御力下降状态

    解除状态下降状态中的物理防御力下降状态和异能防御力下降状态。
`