export default `
### Q：弱体化状态

A：一部分的技能/必杀技有弱体化状态。

弱体化状态在到回合数后，或者是受到'弱体化状态解除'后效果结束。

-   **关于效果的重复**


-   状态下降

    可以同时给予效果。

    ※效果和效果回合是分别计算的。

-   特殊状态

    可以同时给予效果。

    ※效果回合也不改变。

    ※异常状态的'持续被害'可以同时给予效果，效果和效果回合分别计算。


-   **状态下降**

作用于以下状态，各个数值会发生变化。

-   物理攻击力降低

-   异能攻击力降低

-   物理防御力降低

-   异能防御力降低

-   ＳＰ获得量降低

-   红属性伤害降低

-   蓝属性伤害降低

-   绿属性伤害降低

-   暴击发生率降低

-   暴击回避率降低

-   暴击威力降低


-   **特殊状态**

    会发生特殊的效果


-   出血

    行动结束后，受到最大HP值10%的伤害。

    ※来自于出血的伤害不会使HP变为0。

-   持续被害

    行动结束后，受到固定的伤害。

    持续被害的固定伤害受给予者的'灵巧'影响。

    ※来自于出血的伤害可以使HP变为0。

    ※异常状态的'持续被害'可以同时给予效果，效果和效果回合分别计算。

-   气绝

    不能进行任何行动。

-   移动不能

    不能进行移动。

-   感电

    不能进行普通攻击、技能、必杀技、连携。

-   攻击方向减少-1，-2

    详情请去观看Q：关于强化状态和弱体化状态的攻击方向的项目。

-   技能封印

    不能使用战斗技能。

-   必杀技封印

    不能使用必杀技。

-   辅助封印

    辅助技能不能发动。

-   强化妨碍

    对象的'状态上升'和'特殊状态'发动时会变为'强化失败'，且不给予效果。

    ※特殊区域的'攻击区域''防御区域'的状态提升也变为'强化失败'。

    ※给予'强化妨碍'状态前发生效果的'状态提升'与'特殊状态'不会被解除。

    被附加的效果的回合数也不会变化。

-   攻击力提升妨碍

    对象的'物理攻击力增加'和'异能攻击力增加'发动时会变为'强化失败'，且不给予效果。

    ※特殊区域的'攻击区域'的状态提升也变为'强化失败'。

    ※给予'强化妨碍'状态前发生效果的'物理攻击力增加'与'异能攻击力增加'不会被解除。

    被附加的效果的回合数也不会变化。

-   弱体化解除妨碍

    解除弱体化状态（状态下降、异常状态）的效果无效，保护当前被附加的弱体化状态。

-   HP恢复妨碍

    对象'HP恢复'发动时变为'HP恢复失败'，妨碍'HP恢复'效果的发动。

    对象在'不屈'的状态下HP变为0时，变为'HP恢复失败'，妨碍'不屈'效果的发动。

    ※特殊区域的'恢复区域'的HP恢复也变为'HP恢复失败'。

    ※被附加'HP恢复妨碍'时，不能附加'不屈'。

    ※被附加了'HP恢复妨碍'与'不屈'时，'HP恢复妨碍'被解除后，'不屈'能正常发动。

-   制御不能

    不能进行所有的行动，在行动开始前强制移动到随机区域。

    ※随机抽选的结果有时会让当前区域看起来没有被移动。

    如果移动目的地有角色的话，交换场所。

    但是在不能移动的状态下不进行强制移动。

    ※不能移动的区域或有不能移动的角色的区域不在随机移动范围内。

    无效伤害的特殊状态的效果不能发动。

    ※因为'无效伤害的效果'不能发动，所以不会消耗'无效伤害的效果'的次数。
`