export default `
### Q：关于强化状态与弱体化状态的攻击方向

A：一部分的技能/必杀技会带有强化状态或弱体化状态其中一个效果。

-   **强化状态的特殊状态**

-   攻击方向+1

    攻击方向按前、左、右的优先顺序增加1个方向。

    弱体化状态的攻击方向减少的情况下，将减少的方向抵消。

    ※攻击范围最多为3个方向。

-   攻击方向+2

    攻击方向按前、左、右的优先顺序增加2个方向。

    弱体化状态的攻击方向减少的情况下，将减少的方向抵消。

    ※攻击范围最多为3个方向。

-   **弱体化状态的特殊状态**

-   攻击方向-1

    攻击方向按前、左、右的优先顺序减少1个方向。

    弱体化状态的攻击方向减少的情况下，将减少的方向抵消。

    ※攻击范围最少为1个方向。

-   攻击方向-2

    攻击方向按前、左、右的优先顺序减少2个方向。

    弱体化状态的攻击方向减少的情况下，将减少的方向抵消。

    ※攻击范围最多为1个方向。

-   把攻击方向变为前方向

-   把攻击方向变为左方向

-   把攻击方向变为右方向

    强制改变攻击方向。

-   **关于效果的重复**

-   特殊状态

    不能给予相同上升值的重复效果。

    例）攻击方向增加+1与攻击方向增加+1不能同时附加

    例）攻击方向增加+1与攻击方向增加+2能同时附加

-   异常状态

    不能给予相同下降值的重复效果。

    例）攻击方向增加-1与攻击方向增加-1不能同时附加

    例）攻击方向增加-1与攻击方向增加-2能同时附加

-   **关于攻击方向的强化状态和弱体化状态重叠的情况**

-   战斗角色的攻击方向是3个方向（←↑→）时，

    同时被附加攻击方向增加+2的强化状态和攻击方向减少-2弱化状态时，

    根据战斗角色的攻击方向+（攻击方向增加-攻击方向减少）的计算式。

    攻击方向保持3个方向。

-   同样，战斗角色的攻击方向是1个方向（←）时，

    同时被附加攻击方向增加+1的强化状态和攻击方向减少-2弱化状态时，

    根据战斗角色的攻击方向+（攻击方向增加-攻击方向减少）的计算式。

    攻击方向保持1个方向。

-   **在强化状态时攻击方向变为【前・左・右】的情况**

-   战斗角色的攻击方向是3个方向（←↑→）时，

    同时被附加攻击方向增加+2的强化状态。然后被附加'把攻击方向变为前方向'，攻击方向变为单方向（↑），然后根据战斗角色的攻击方向+（攻击方向增加-攻击方向减少）的计算式。攻击方向保持3个方向。
`